import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { Router} from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { JwtService } from '../../services/jwt.service';
import { OfflineService } from '../../services/offline.service';
import { OrganisationConfigModel } from '../../models/organisation-config.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  sideNavWidth = 60; // Initial width of SideNav
  openMenuDisplay = 'none'; // Expanded menu is hidden on component initialisation
  closedMenuDisplay = 'block'; // Collapsed menu is shown by default on component initialisation
  menuExpanded = false;
  roles: string[];

  organisation: OrganisationConfigModel;

  @ViewChild('mySideNav', { static: true }) mySideNav;

  constructor(public sideNavService: NavigationService,
              public authService: AuthService,
              public offlineService: OfflineService,
              public jwtService: JwtService,
              private router: Router,
              private translate: TranslateService,
            ) {
  }

  ngOnInit() {
    this.organisation = JSON.parse(localStorage.getItem('organisationConfig'));
    this.getRolesList();
  }

  getWrapperClass() {
    if (this.sideNavService.navIsOpen) {
      return 'open-menu-item-wrapper';
    }
    return 'closed-menu-item-wrapper';
  }

  public getMenuThemeSettings(itemDomain: string | string[]): any {
    if (typeof itemDomain === 'string') {
      itemDomain = [itemDomain];
    }
    let themeSetting = {}
    itemDomain.forEach((item) => {
      if (this.router.isActive(item, false)) {
        themeSetting = {
          borderLeftColor: 'accent',
          color: 'secondary'
        };
      }
    })
    return  themeSetting;
  }

  public overlayClick() {
    if (this.sideNavService.isNavOpen()) {
      this.sideNavService.toggleNav();
    }
  }

  public logoutClick() {
    this.authService.logout();
    this.sideNavService.toggleNav();
    this.offlineService.isOffline = false;
    this.router.navigate(['login']);
  }

  goto(path: Array<string>) {
    this.router.navigate(path);
    if (this.sideNavService.isNavOpen()) {
      this.sideNavService.toggleNav();
    }
  }

  /**
   * Get the list of sorted translated roles from the JWT service.
   * @returns {Promise<void>}
   */
  private async getRolesList(): Promise<void> {
    this.roles = await this.jwtService.getTranslatedRoles(this.translate);
  }
}

import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OrganisationService } from './organisation.service';

@Injectable()
export class AccountSearchInterceptor implements HttpInterceptor {

  constructor(private router: Router, private organisationService: OrganisationService) {}

  /**
   * This interceptors purpose is to intercept the account search request and remove accounts from the response based on org configs disabledAccountLocations.
   * @param httpRequest - The request to be intercepted.
   * @param next - The next handler in the chain.
   * @returns Observable<HttpEvent<any>>
   */
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentPage = this.router.url;
    const allowedPages = [
      '/events/new',
      '/events/update',
      '/events/clone',
      '/templates/create',
      '/templates/update'
    ];

    // Only intercept requests from the pages listed in allowedPages.
    if (!allowedPages.some(path => currentPage.includes(path))) {
      return next.handle(httpRequest);
    }

    // Return early if not a account search request (don't modify request).
    if (!this.isAccountSearch(httpRequest.url, environment.api.partnershipSearch)) {
      return next.handle(httpRequest);
    }

    // Account IDs to exclude from the search results.
    const disabledAccountLocations = this.organisationService.organisation.eventConfig.disabledAccountLocations || [];

    // Remove excluded accounts from the response.
    return next.handle(httpRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const accounts = event.body;

          return event.clone({
            body: accounts.filter(account => disabledAccountLocations.indexOf(account.parent_externalId) === -1)
          });
        }

        return event;
      })
    );
  }

  /**
   * Check if a URL matches a specified regex pattern.
   * @param url - The URL to check.
   * @param regexPattern - The regex pattern to match against.
   * @returns boolean - True if the URL matches the regex pattern, false otherwise.
   */
  private isAccountSearch(url: string, regexPattern: string): boolean {
    const regex = new RegExp(regexPattern.replace('{id}', '[^/]+').replace('{hierarchyLevel}', '[^/]+'));
    return regex.test(url);
  }
}

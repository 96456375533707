
import {filter, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';





import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';



@Injectable()
export class TitleService {
  title: string;
  private _ORG_NAME: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    this._ORG_NAME = localStorage.getItem('organisationConfig') ?
      JSON.parse(localStorage.getItem('organisationConfig')) : { shortName: 'etrainu' };
  }

  init() {
    this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => {
      let route = this.activatedRoute;
      while (route.firstChild) { route = route.firstChild; }
      return route;
    }),
    filter((route) => route.outlet === 'primary'),
    mergeMap((route) => route.data),
    map(() => {
      return 'assess';
    }),)
    .subscribe((pathString) => {
      this.titleService.setTitle(`${this._ORG_NAME.shortName || this._ORG_NAME.name} | ${pathString}`);
    });
  }

  setRouteTitle() {
    this.init();
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        return this.activatedRoute.snapshot.children[0].children[0].data.title;
      }),);
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as jwt_decode from 'jwt-decode';

export enum UserRoles {
  ASSESSOR = 'ASSESSOR',
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  PARTNERSHIP = 'PARTNERSHIP',
  REVIEWER = 'REVIEWER',
  TRAINER = 'TRAINER',
  ENDORSED_DELEGATE = 'ENDORSED_DELEGATE',
  LEVEL_1_REVIEWER = 'LEVEL_1_REVIEWER',
  LEVEL_2_REVIEWER = 'LEVEL_2_REVIEWER',
  DEP_ADMIN = 'DEP_ADMIN',
  SUBORG_ADMIN = 'SUBORG_ADMIN',
  ORG_ADMIN = 'ORG_ADMIN'
}

export type Jwt = {
  iss: string;
  exp: number;
  nbf: number;
  iat: number;
  aud: string;
  jti: string;
  scopes: string[];
  sub: string;
  name: string;
  email: string;
  username: string;
  contxt: string;
  contextName: string;
  explicitAccountLocations: string[];
}

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  private userEmail: string;
  private userInitial: string;
  private contextOrg: { id: string, name: string };

  /**
   * Get user name from JWT.
   * @returns {string}
   */
  public getUserName(): string {
    return Reflect.has(this.getJwt(), 'name') ? this.getJwt().name : ''
  }

  /**
   * Get user email from JWT.
   * @returns {string}
   */
  public getUserEmail(): string {
    this.userEmail = this.getJwt().email;
    return this.userEmail;
  }

  /**
   * Get user id from JWT.
   * @returns {string}
   */
  public getUserId(): string {
    return this.getJwt().sub;
  }

  /**
   * Get user initial from JWT.
   * @returns {string}
   */
  public getUserInitial(): string {
    this.userInitial = this.getUserName()[0].toUpperCase();
    return this.userInitial;
  }

  /**
   * Get org context from JWT.
   * @returns {{ id: string, name: string }}
   */
  public getContextOrg(): { id: string, name: string } {
    const { contxt, contextName } = this.getJwt();
    this.contextOrg = { id: contxt, name: contextName };
    return this.contextOrg;
  }

  /**
   * Get roles from JWT.
   * @returns {string[]}
   */
  public getRoles(): string[] {
    return this.getJwt().scopes;
  }

  /**
   * Get sorted, translated roles.
   * @param translateService 
   * @returns {Promise<string[]>}
   */
  public async getTranslatedRoles(translateService: TranslateService): Promise<string[]> {
    const scopes = this.getJwt().scopes;

    // Map of scopes to translation keys.
    const translationMap = {
      ASSESSOR: 'roles.assessor',
      ORG_ADMIN: 'roles.organisationAdmin',
      DEP_ADMIN: 'roles.departmentAdmin',
      LEVEL_1_REVIEWER: 'roles.level1Reviewer',
      LEVEL_2_REVIEWER: 'roles.level2Reviewer',
      SUBORG_ADMIN: 'roles.subOrgAdmin',
      ENDORSED_DELEGATE: 'roles.endorsedDelegate',
      REVIEWER: 'roles.reviewer',
      TRAINER: 'roles.trainer',
      BRANCH_ADMIN: 'roles.branchAdmin',
    };

    const translationPromises = scopes
      .filter(scope => translationMap.hasOwnProperty(scope))
      .map(scope => translateService.get(translationMap[scope]).toPromise());

    const translatedRoles: string[] = await Promise.all(translationPromises);

    return translatedRoles.sort((a, b) => a.localeCompare(b));
  }

  /**
   * Get account locations from JWT.
   * @returns {string[]}
   */
  public getAccountLocations(): string[] {
    return this.getJwt().explicitAccountLocations
  }

  /**
   * Get JWT from local storage.
   * @returns {Jwt}
   */
  private getJwt(): Jwt {
    const jwt = localStorage.getItem('current_user');
    return jwt_decode(jwt);
  }
}

<app-loading></app-loading>
<div id="app-wrapper" [ngClass]="globalHeaderService.mobileClasses" *ngIf="showContent">
  <app-side-nav *ngIf="!isEnrolRoute() && authService.isAuthenticated() && authService.notParticipant()"></app-side-nav>
  <div class="page-title-container" *ngIf="!isEnrolRoute() && authService.isAuthenticated() && authService.notParticipant()" [ngStyle]="getBannerBackground()" [ngClass]="{'sidenav-open': sideNavService.navIsOpen}">
    <div class="page-title-inner">
      <button type="button" (click)="sideNavService.toggleNav()" class="icon-button nav-toggle">
        <mat-icon [appThemed]="{'color': 'leftBannerIconColour'}" fontSet="etrainu-icon-regular" fontIcon="icon-menu"></mat-icon>
      </button>
      <h1 [appThemed]="{color: 'leftBannerIconColour'}" class="page-title">{{title}}</h1>
      <div class="flex-spacer"></div>
      <div class="global-actions">
        <app-refresh-button [eventId]="eventsService.selectedEvent._id"
                            (refreshComplete)="globalHeaderService.onMenuItemSelected({label: '', value: 'refresh-event'})"
                            [disabled]="offlineService.isOffline"
                            [appThemed]="{'color': 'rightBannerIconColour'}"
                            *ngIf="globalHeaderService.showRefreshEvent && eventsService.selectedEvent"></app-refresh-button>
        <div id="offline-switch" *ngIf="authService.isAuthenticated()">
          <button mat-icon-button (click)="offlineService.toggleOffline()">
            <mat-icon [appThemed]="{'color': 'rightBannerIconColour'}" fontSet="etrainu-icon-bold" fontIcon="{{ (offlineService.isOffline) ? 'icon-wifi-off' : 'icon-wifi' }}"></mat-icon>
          </button>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="globalHeaderService.menuItems.length > 0">
          <mat-icon [appThemed]="{'color': 'rightBannerIconColour'}" fontSet="etrainu-icon-regular" fontIcon="icon-more-vert"></mat-icon>
          <mat-menu #menu="matMenu">
            <div *ngFor="let item of globalHeaderService.menuItems" mat-menu-item
              (click)="globalHeaderService.onMenuItemSelected(item)"
              [ngClass]="{'disabled': item.disabled}"
            >
              <span>{{item.label}}</span>
            </div>
          </mat-menu>
        </button>
      </div>
    </div>
  </div>
  <div class="main-content" id="app-content-container" [ngClass]="{'no-menu':  isEnrolRoute() || !authService.isAuthenticated() || !authService.notParticipant(), 'open-nav': sideNavService.navIsOpen}">
    <router-outlet></router-outlet>
  </div>
</div>

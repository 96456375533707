import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class CourseSearchInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  /**
   * This interceptors purpose is to intercept the course search request and remove excluded courses from the response on the event creation/update/clone pages.
   * @param httpRequest - The request to be intercepted.
   * @param next - The next handler in the chain.
   * @returns Observable<HttpEvent<any>>
   */
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Don't filter out courses if not on the event creation/update/clone pages.
    if (!this.router.url.includes('/events/new') && !this.router.url.includes('/events/update') && !this.router.url.includes('/events/clone')) {
      return next.handle(httpRequest);
    }

    // Return early if not a course search (don't modify request).
    if (!httpRequest.url.includes(environment.api.courseSearch)) {
      return next.handle(httpRequest);
    }

    // Remove courses that have hideInEventCreateForm property set to true from the response.
    return next.handle(httpRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body.filter(course => !course.assessmentEventProperties.hideInEventCreateForm)
          });
        }

        return event;
      })
    );
  }
}

import {ErrorHandler, Injectable} from '@angular/core';

declare var newrelic;

@Injectable({
  providedIn: 'root'
})

export class NewRelicErrorHandlerService implements ErrorHandler {
  handleError(error) {
    // Throwing a new error in this service will avoid Angular's error handling
    // This will lead to New Relic correctly picking up errors
    if (typeof newrelic !== 'undefined') {
      newrelic.noticeError(error);
    }
    console.error(error)
  }

  constructor() { }
  
}

import {NgModule} from '@angular/core';
import {ThemedDirective} from './themed/themed.directive';
import {TabContainerDirective} from './tab-container/tab-container.directive';
import { FocusScrollDirective } from './focus-scroll/focus-scroll.directive';

@NgModule({
  declarations : [ThemedDirective, TabContainerDirective, FocusScrollDirective],
  exports: [ThemedDirective, TabContainerDirective, FocusScrollDirective]
})

export class DirectivesModule {}

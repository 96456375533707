import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  navIsOpen = false;
  sideNavWidth = 100;
  paddingLeft = 100;
  iconName = 'icon-menu-left';
  closeIconName = 'icon-menu-right';
  openIconName = 'icon-menu-left';
  private previous_page: Array<string>;
  private current_page: Array<string>;
  private activeTab: string;

  constructor(router: Router) {
    this.current_page = router.url.split('/');
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previous_page = this.current_page;
        this.current_page = event.url.split('/');
      }
    });
  }
  isNavOpen() {
    return this.navIsOpen;
  }

  setNavIsOpen(bool) {
    this.navIsOpen = bool;
  }

  getNavWidth() {
    return this.sideNavWidth;
  }

  getPaddingLeft() {
    return this.paddingLeft;
  }

  getIconName() {
    if (this.navIsOpen) {
      return this.closeIconName;
    } else {
      return this.iconName;
    }
  }

  expandNav() {
    this.sideNavWidth = 300;
    this.paddingLeft = 300;
    this.iconName = this.closeIconName; // change to menu-right when icons come
    this.navIsOpen = true;
  }

  shrinkNav() {
    this.sideNavWidth = 100;
    this.paddingLeft = 100;
    this.iconName = this.openIconName; // change to menu-left when icons come
    this.navIsOpen = false;
  }

  toggleNav() {
    if (this.navIsOpen) {
      setTimeout(() => {
        this.shrinkNav();
      }, 100);
    } else {
      setTimeout(() => {
        this.expandNav();
      }, 100);
    }
  }

  getPreviousPage(): Array<string> {
    return (
      this.current_page.join('/') !== this.previous_page.join('/') &&
      this.previous_page.join('/') !== '/'
    ) ? this.previous_page : null;
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  getActiveTab(): string {
    return this.activeTab;
  }
}

import {EventEmitter, Injectable} from '@angular/core';
import { NgForage } from 'ngforage';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CacheUtilsService } from '../shared/cache-utils.service';
import {EventModel} from '../models/event.model';
import * as moment from 'moment-timezone';
import {MatSnackBar} from '@angular/material/snack-bar';
export interface StoredData {
  body: HttpRequest<any> | EventModel | EventModel[];
  date: Date;
  type: string;
  request: boolean;
  error?: string;
}

export enum StoredDataType {
  ASSESS = 'events-assess',
  BULK_ASSESS = 'events-bulk-assess',
  SIGN_IN = 'events-register-signin',
  REMOVE = 'events-register-remove'
}

@Injectable({providedIn: 'root'})
export class OfflineService {
  public isOffline: boolean;
  public offlineToggleEmitter: EventEmitter<any>;
  constructor(
    private ngf: NgForage,
    private http: HttpClient,
    private route: ActivatedRoute,
    private cacheUtilService: CacheUtilsService,
    private snackBar: MatSnackBar
  ) {
    this.isOffline = false;
    this.offlineToggleEmitter = new EventEmitter<any>();
  }

  async setOfflineRequest(request: HttpRequest<any>, type) {
    const newIndex = `${type}-${this.cacheUtilService.getUUID()}`;
    const data: StoredData = {
      body: request,
      date: moment().toDate(),
      type: type,
      request: true,
      error: null
    };
    this.ngf.setItem(newIndex, data).then((res) => {
      return newIndex;
    }).catch((err) => {
      console.error(err);
    });
  }

  async getOfflineRequests() {
    const requestArray = [];
    await this.ngf.iterate((stored: StoredData, key) => {
      if (stored.request) {
        // check that we are only getting requests from offline storage
        requestArray.push(stored);
      }
    });
    return requestArray;
  }

  public toggleOffline() {
    this.isOffline = !this.isOffline;
    this.offlineToggleEmitter.emit(this.isOffline);
    if (!this.isOffline) {
      this.processRequests();
      this.snackBar.open("Online");
    } else {
      this.snackBar.open("Offline");
    }
  }

  public getOfflineToggleEmitter() {
    return this.offlineToggleEmitter;
  }

  async processRequests() {
    const requests = [];
    await this.ngf.iterate((storedData: StoredData, key: string, index: number) => {
      requests.push({
        key,
        storedData
      });
    });
    requests.sort((a, b) => {
      const aDate = moment(a.storedData.date);
      const bDate = moment(b.storedData.date);
      return (aDate.isBefore(bDate) ? -1 : (bDate.isBefore(aDate) ? 1 : 0));
    });
    requests.forEach(({key, storedData}) => {
      let requestSubmit = null;
      // check if the stored value is a url
      if (storedData.body && (storedData.body instanceof HttpRequest || ('url' in storedData.body && 'method' in storedData.body))) {

        // Remove request from IndexedDB if it is older than 1 week
        if(storedData.date && moment().isAfter(moment(storedData.date).add(1, 'week'))) {
          return this.ngf.removeItem(key);
        }

        switch (storedData.body.method.toLowerCase()) {
          case 'get':
            requestSubmit = this._get(storedData.body);
            break;
          case 'post':
            requestSubmit = this._post(storedData.body);
            break;
          case 'put':
            requestSubmit = this._put(storedData.body);
            break;
          case 'delete':
            requestSubmit = this._delete(storedData.body);
            break;
          default:
            throw(Error('No request type available offline'));
        }
      }
      if (requestSubmit) {
        requestSubmit.subscribe((res) => {
          this._handleSuccess(key, res);
        }, (err) => {
          this._handleError(key, err);
        });
      }
    });
  }

  private _handleSuccess(ngfKey: string, request) {
    this.ngf.removeItem(ngfKey);
    setTimeout(() => {
      this.offlineToggleEmitter.emit(false);
    });
  }
  private async _handleError(ngfKey, err) {
    console.error(err);
    const storedData: any = await this.ngf.getItem(ngfKey);
    storedData.error = err.error.message || err.message;
    this.ngf.setItem(ngfKey, storedData);
    setTimeout(() => {
      this.offlineToggleEmitter.emit(false);
    });
  }

  private _get(request: HttpRequest<any>) {
    return this.http.get(request.urlWithParams);
  }

  private _post(request: HttpRequest<any>) {
    return this.http.post(request.url, request.body);
  }

  private _put(request: HttpRequest<any>) {
    return this.http.put(request.url, request.body);
  }

  private _delete(request: HttpRequest<any>) {
    return this.http.delete(request.url);
  }
}

import {Injectable} from "@angular/core";
import * as moment from 'moment-timezone';
import {UnitsService} from "./units.service";
import {AssessmentStatus} from "../../../../models/course.model";
import { Observable } from "rxjs";
import {TranslateService} from "@ngx-translate/core";

export enum AssessmentType {
  BULK = 'undo_bulk',
  SINGLE = 'undo_single'
}

interface UndoItem {
  type: AssessmentType,
  date: String,
  data: any
}

const UNDO_LIST_LOCAL_STORAGE_NAME = 'undo_list';

@Injectable({providedIn:"root"})
export class UndoAssessmentService {
  private undoItems: UndoItem[];
  private eventId: string;
  private courseId: string;
  constructor(
    private assessmentService: UnitsService,
    private translate: TranslateService,
  ) {
    this.undoItems = this.getUndoItemsInLocalStorage();
    this.eventId = this.getEventIdInLocalStorage();
    this.courseId = this.getCourseIdInLocalStorage();
  }

  setAssessment(data, type: AssessmentType, eventId: string, courseId: string) {
    this.clearUndoList(eventId, courseId);
    if (this.undoItems.length >= 5) {
      while(this.undoItems.length >= 5) {
        this.undoItems.splice(0, 1);
      }
    }
    this.undoItems.push({
        type,
        data,
        date: moment().format(),
    });
    this.setUndoInLocalStorage();
  }

  undoAssessment(eventId: string, courseId): Observable<any> {
    this.clearUndoList(eventId, courseId);
    return new Observable((observer) => {
      if (this.undoItems.length > 0) {
        const nextUndo = this.undoItems[this.undoItems.length - 1];
        if (nextUndo.type === AssessmentType.BULK) {
          this.assessmentService.updateAssessmentBulkStatus(nextUndo.data).subscribe(() => {
            this.undoItems.pop();
            this.setUndoInLocalStorage();
            this.translate.get('snackbarMessages.undoAssessment').subscribe((value) => {
              observer.next(value);
              observer.complete();
            });
          });
        } else if (nextUndo.type === AssessmentType.SINGLE) {
          this.assessmentService.updateAssessmentStatus(nextUndo.data).subscribe(() => {
            this.undoItems.pop();
            this.setUndoInLocalStorage();
            this.translate.get('snackbarMessages.undoAssessment').subscribe((value) => {
              observer.next(value);
              observer.complete();
            });
          });
        }
      } else {
        this.translate.get('snackbarMessages.noUndoesLeft').subscribe(value => {
          observer.error(value);
          observer.complete();
        });

      }
    });
  }

  getUndoesLeft(): number {
    return this.undoItems.length;
  }

  resetUndoActions(eventId?: string) {
    let reset = true;
    // no need to reset if the eventIds match
    if (!!eventId && eventId === this.eventId) {
      reset = false;
    }
    if (reset) {
      this.eventId = '';
      this.courseId = '';
      this.undoItems = [];
      this.setUndoInLocalStorage();
    }
  }

  private clearUndoList(eventId: string, courseId: string) {
    if (eventId !== this.eventId || courseId !== this.courseId) {
      this.undoItems = [];
      this.eventId = eventId;
      this.courseId = courseId;
      this.setUndoInLocalStorage();
    }
  }

  private getUndoItemsInLocalStorage(): UndoItem[] {
    const { undoItems } = JSON.parse(localStorage.getItem(UNDO_LIST_LOCAL_STORAGE_NAME)) || { undoItems: [] };
    return !!undoItems ? undoItems : [];
  }
  private getEventIdInLocalStorage(): string {
    const { eventId } = JSON.parse(localStorage.getItem(UNDO_LIST_LOCAL_STORAGE_NAME)) || { eventId: '' };
    return !!eventId ? eventId : '';
  }
  private getCourseIdInLocalStorage() {
    const { courseId } = JSON.parse(localStorage.getItem(UNDO_LIST_LOCAL_STORAGE_NAME)) || { courseId: '' };
    return !!courseId ? courseId : '';
  }
  private setUndoInLocalStorage() {
    const data = JSON.stringify({
      undoItems: this.undoItems,
      eventId: this.eventId,
      courseId: this.courseId,
    });
    localStorage.setItem(UNDO_LIST_LOCAL_STORAGE_NAME, data);
  }
}

// Modules
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DirectivesModule } from './directives/directives.module';
import { RefreshButtonModule } from './components/refresh-button/refresh-button.module';

// Components
import { AppComponent } from './app.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingModule } from './components/loading/loading.module';

// Services
import { NavigationService } from './services/navigation.service';
import { TokenInterceptor } from './services/token-interceptor';
// import { NgForageConfig} from 'ngforage';
import { NgForageOptions, Driver, DEFAULT_CONFIG } from 'ngforage';
import { OfflineService } from './services/offline.service';
import { TitleService } from './shared/title.service';
import { NewRelicErrorHandlerService } from './services/new-relic-error-handler.service';
import { PipesModule } from './modules/pipes.module';
import { environment } from 'src/environments/environment';
import { CourseSearchInterceptor } from './services/course-search-interceptor.service';
import { AccountSearchInterceptor } from './services/account-search-interceptor.service';

const  MobileDetect = require('mobile-detect'), md = new MobileDetect(window.navigator.userAgent);

declare var cordova;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.static.organisationAppConfig[0].currentVersion}`);
}


@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    DirectivesModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    RefreshButtonModule,
    LoadingModule,
    PipesModule,
  ],
  providers: [
    NavigationService,
    OfflineService,
    TitleService,
    {provide: ErrorHandler, useClass: NewRelicErrorHandlerService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CourseSearchInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccountSearchInterceptor,
      multi: true
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        panelClass: 'otb-snack'
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'otb-dialog',
        hasBackdrop: true,
        minWidth: md.isPhoneSized() ? '100vw' : '75vw',
        maxWidth: md.isPhoneSized() ? '100vw' : '75vw'
      }
    },
    {
      provide: DEFAULT_CONFIG,
      useValue: {
        name: 'assessApp',
        driver: [
          Driver.INDEXED_DB,
          Driver.LOCAL_STORAGE
        ]
      } as NgForageOptions
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}

import {NgModule} from '@angular/core';
import {RefreshButtonComponent} from './refresh-button.component';
import { MatIconModule } from '@angular/material/icon';
import {DirectivesModule} from '../../directives/directives.module';
import {CommonModule} from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from "@angular/material/menu";


@NgModule({
  declarations: [RefreshButtonComponent],
  imports: [CommonModule, MatIconModule, DirectivesModule
    ,NgSelectModule, FormsModule, MatMenuModule
  ],
  exports: [RefreshButtonComponent]
})

export class RefreshButtonModule {}

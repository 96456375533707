import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _isLoading: boolean;
  private _showOverlay: boolean;

  set isLoading(value: boolean) {
    if (value) {
      this._isLoading = value;
    } else {
      setTimeout(() => { this._isLoading = value; }, 500);
    }
  }

  set showOverlay(value: boolean) {
    this._showOverlay = value;
  }

  get isLoading(): boolean { return this._isLoading; }

  get showOverlay(): boolean { return this._showOverlay; }

  constructor() {
    this._isLoading = false;
    this._showOverlay = true;
  }
}

<!-- Sidenav container -->
<div #mySideNav id="mySidenav" class="sidenav" [ngClass]="{'expanded': sideNavService.navIsOpen}">
  <!-- Closed/collapsed menu structure start -->
  <div [ngClass]="getWrapperClass()">
    <div [ngClass]="{'expanded-side-nav-menu-item': openMenuDisplay}" class="profile-avatar">
      <div>
        <a (click)="sideNavService.toggleNav()">
          <div class="profile-pic" [appThemed]="{'background': 'light'}">
            <img src="{{ this.organisation.logo }}" class="logo" alt="">
          </div>
        </a>
      </div>
      <span [hidden]="!sideNavService.isNavOpen()">
        <h5 class="first-name">{{ jwtService.getUserName() }}</h5>
      </span>
    </div>
    <div [hidden]="!sideNavService.isNavOpen()" class="profile-info">
      <h6>{{'components.sideNav.lmsLevel' | translate | uppercase }}</h6>
      <div class="lmsLevel">
        <span *ngFor="let role of roles; let i = index">{{ role }}{{((i + 1) < roles.length && roles.length > 1) ? ', ' : ''}}</span>
      </div>

      <h6>{{'components.sideNav.usernameOrEmail' | translate | uppercase }}</h6>
      <p>{{ jwtService.getUserEmail() }}</p>
      <div class="user-buttons-container">
        <button [appThemed]="{'color': 'secondary'}" class="otb-button" (click)="logoutClick()"><mat-icon fontSet="etrainu-icon-regular" fontIcon="icon-logout"></mat-icon> {{ 'buttons.logout' | translate }}</button>
      </div>
    </div>
    <div class="menu-item-wrapper"
       [appThemed]="getMenuThemeSettings('dashboard')"
       [ngClass]="{'expanded-side-nav-menu-item' : sideNavService.isNavOpen()}"
       (click)="goto(['dashboard'])" routerLink="/dashboard" routerLinkActive="active"
       matTooltip="Dashboard"
       matTooltipPosition="right"
       [matTooltipDisabled]="sideNavService.isNavOpen()">

      <div [ngClass]="{'side-nav-menu-item': !sideNavService.isNavOpen()}">
        <mat-icon [appThemed]="getMenuThemeSettings('dashboard')" fontSet="etu-icon" fontIcon="icon-home-thin"></mat-icon>
      </div>
      <span [hidden]="!sideNavService.isNavOpen()">
        <h5 [appThemed]="getMenuThemeSettings('dashboard')">Dashboard</h5>
      </span>
    </div>
    <div class="menu-item-wrapper" [appThemed]="getMenuThemeSettings(['events', 'templates'])"
         [ngClass]="{'expanded-side-nav-menu-item' : sideNavService.isNavOpen()}"
         (click)="goto(['events'])" routerLink="/events" routerLinkActive="active"
          matTooltip="Events Management" matTooltipPosition="right" [matTooltipDisabled]="sideNavService.isNavOpen()">
      <div [ngClass]="{'side-nav-menu-item': !sideNavService.isNavOpen()}">
        <mat-icon [appThemed]="getMenuThemeSettings(['events', 'templates'])" fontSet="etu-icon" fontIcon="icon-events-thin"></mat-icon>
      </div>
      <span [hidden]="!sideNavService.isNavOpen()">
        <h5 [appThemed]="getMenuThemeSettings(['events', 'templates'])">Events Management</h5>
      </span>
    </div>
    <div class="menu-item-wrapper" [appThemed]="getMenuThemeSettings('help')"
         [ngClass]="{'expanded-side-nav-menu-item' : sideNavService.isNavOpen()}"
         (click)="goto(['help'])" routerLink="/help" routerLinkActive="active"
          matTooltip="Help & Feedback" matTooltipPosition="right" [matTooltipDisabled]="sideNavService.isNavOpen()">
      <div [ngClass]="{'side-nav-menu-item': !sideNavService.isNavOpen()}">
        <mat-icon [appThemed]="getMenuThemeSettings('help')" fontSet="etu-icon" fontIcon="icon-help-circle-thin"></mat-icon>
      </div>
      <span [hidden]="!sideNavService.isNavOpen()">
        <h5 [appThemed]="getMenuThemeSettings('help')">Help & Feedback</h5>
      </span>
    </div>
  </div>
</div>
<div #pageOverlay class="page-overlay" (click)="overlayClick()" [ngClass]="{'show-overlay': sideNavService.isNavOpen(), 'hide-overlay': !sideNavService.isNavOpen()}"></div>

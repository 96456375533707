import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appThemed]'
})
export class ThemedDirective implements OnInit {
  private _styles: any = {};
  @Input('appThemed')
  set appThemed(styles) {
    this._styles = styles || {};
    this.setStyles();
  }

  private theme = {};
  constructor(private el: ElementRef) { }
  ngOnInit() {
    const {
      primaryColour,
      secondaryColour,
      accentColour,
      leftBannerIconColour,
      rightBannerIconColour,
      previouslyAssessedTrainingItemColour
    } = JSON.parse(localStorage.getItem('organisationConfig')).theme;
    this.theme = {
      'primary': primaryColour,
      'secondary': secondaryColour,
      'accent': accentColour,
      'previouslyAssessedTrainingItemColour': previouslyAssessedTrainingItemColour || accentColour,
      'heading': '#000000',
      'light': '#FFFFFF',
      'dark': '#000000',
      'leftBannerIconColour': leftBannerIconColour,
      'rightBannerIconColour': rightBannerIconColour,
    };

    this.setStyles();
  }

  private setStyles(): void {
    // clear all inline styles to revert to default
    this.el.nativeElement.style = null;
    // loop through each key (style property) and apply the color requested
    for (const key of Object.keys(this._styles)) {
      this.el.nativeElement.style[key] = this.theme[this._styles[key]];
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiUtilsService {

  constructor() {}

  /**
   * Sets the API URL by replacing placeholders with values.
   * @param url - The API URL with placeholders
   * @param values - The values to replace the placeholders with
   * @returns string - The API URL with placeholders replaced by values
   */
  public setApiUrl(url: string, values: Array<{ key: string, value: string }>): string {
    values.forEach(param => {
      const re = new RegExp(param.key, 'g');
      url = url.replace(re, param.value);
    });
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }
}

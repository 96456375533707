<div class="loading-container" [ngClass]="{'no-overlay': !loadingService.showOverlay}"  *ngIf="loadingService.isLoading">
  <div class="loading-selected" [appThemed]="{'background': 'primary'}">
      <span class="loading">
        <svg class="loader" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
          <circle class="external-circle" cx="60" cy="60" r="50" [appThemed]="{'stroke': 'light'}"></circle>
        </svg>
      </span>
    <span class="text" [appThemed]="{'color': 'light'}">{{ 'loader.default' | translate }}</span>
  </div>
</div>

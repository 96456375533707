<button mat-icon-button type="button" (click)="onClick($event)" class="refresh-button"
        [ngClass]="{'is-reloading': loading}"
        [disabled]="_disabled">
  <mat-icon [appThemed]="{'color': 'rightBannerIconColour'}" fontSet="etrainu-icon-regular" fontIcon="icon-refresh"></mat-icon>
</button>

<button mat-icon-button [matMenuTriggerFor]="menu" class="refresh-timer">
  <h5 [appThemed]="{'color': 'rightBannerIconColour'}">{{refreshTimer.label}}</h5>
  <mat-menu #menu="matMenu">
    <div *ngFor="let item of timeIntervals" (click)="setTime(item)" class="time-intervals">
      {{item.label}}
    </div>
  </mat-menu>
</button>

import {Injectable} from '@angular/core';
const uuid = require('uuid/v4');
@Injectable({
  providedIn: 'root'
})
export class CacheUtilsService {
  constructor() {}

  public getUUID(): string {
    return uuid();
  }
}

import { Component, Inject, isDevMode, OnDestroy } from '@angular/core';
import { NavigationService } from './services/navigation.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfflineService } from './services/offline.service';
import { GlobalHeaderService } from './services/global-header.service';
import { EventsService } from './services/events.service';
import { OrganisationService } from './services/organisation.service';
import { TitleService } from './shared/title.service';
import { TranslateService } from '@ngx-translate/core';
import { brand } from '../brands/brand';
import {JwtService} from './services/jwt.service';
import {OrganisationConfigModel} from './models/organisation-config.model';
import {DOCUMENT} from '@angular/common';
import {environment} from '../environments/environment';
import { PermissionsService, RoleKeys } from './services/permissions-service/permissions.service';
import { ReviewerConfig } from './models/event.model';

declare var cordova;
declare var StatusBar;
declare var device;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  public title;
  public organisation;
  public showContent: boolean;
  private routerSubscription;

  constructor(
    public sideNavService: NavigationService,
    public globalHeaderService: GlobalHeaderService,
    public eventsService: EventsService,
    public authService: AuthService,
    public offlineService: OfflineService,
    public organisationService: OrganisationService,
    private router: Router,
    private route: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private titleService: TitleService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private jwtService: JwtService,
    private permissionsService: PermissionsService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    this.showContent = false;
    translate.setDefaultLang('etrainu');
    matIconRegistry.registerFontClassAlias('etrainu-icon-regular', 'etrainu-icon-regular');
    matIconRegistry.registerFontClassAlias('etrainu-icon-bold', 'etrainu-icon-bold');
    matIconRegistry.registerFontClassAlias('etrainu-icon-thin', 'etrainu-icon-thin');
    document.addEventListener('deviceready', () => { this.onDeviceReady() }, false);

    if (!isDevMode()) {
      const scriptSDK = this._document.createElement('script');
      if (environment.env === 'prd') {
        // Load in new relic script
        scriptSDK.src = `./assets/js/newrelic.js`;
      } else if (environment.env === 'stg') {
        // Load in new relic script
        scriptSDK.src = `./assets/js/newrelic-staging.js`;
      }
      scriptSDK.defer = true;
      scriptSDK.async = true;
      this._document.body.appendChild(scriptSDK);
      // disable logging in prod mode
      if (window) {
        window.console.log = function() {};
      }
    }
    this.offlineService.processRequests();
    // decide on branding based on the app build
    this._setOrganisationBranding();
  }

  ngOnDestroy(): void {
    // Unsubscribe router subscription.
    this.routerSubscription.unsubscribe();
  }

  setTitle () {
    this.titleService.setRouteTitle().subscribe(data => {
      if (data) {
        this.title = data;
      } else {
        this.title = '';
      }
    });
  }

  public getBannerBackground() {
    if (this.organisationService.organisation.banner.length > 0) {
      return {
        'background-image': `url(${this.organisationService.organisation.banner})`,
        'background-color': this.organisationService.organisation.theme.bannerBackground,
      };
    } else {
      return {
        'background-color': this.organisationService.organisation.theme.bannerBackground,
      };
    }
  }

  public isEnrolRoute() {
    return this.router.isActive('enrol', {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'});
  }

  private onDeviceReady() {
    if (cordova.platformId === 'android' && typeof StatusBar.styleDarkContent === 'function') {
      StatusBar.styleDarkContent();
    }
    this.checkPhoneType();
  }

  private checkPhoneType() {
    const mobileClasses = ['device'];
    let iPhoneWithNotch = ['x86_64', 'iPhone10,3', 'iPhone10,6', 'iPhone11', 'iPhone12', 'iPhone13', 'iPhone11,2', 'iPhone11,4', 'iPhone11,6', 'iPhone11,8', 'iPhone12,1', 'iPhone12,3', 'iPhone12,5', 'iPhone12,8', 'iPhone13,1', 'iPhone13,2', 'iPhone13,3 ', 'iPhone13,4', 'iPhone14,2', 'iPhone14,3', 'iPhone14,4', 'iPhone14,5', 'iPhone14,6'];
    if (iPhoneWithNotch.indexOf(device.model.split(',')[0]) >= 0 || iPhoneWithNotch.indexOf(device.model) >= 0) {
      // ensure its an iPhoneX not an 8
      if (device.model === 'iPhone10,3' || device.model === 'iPhone10,6' ) {
        mobileClasses.push('iPhoneX');
      } else if (iPhoneWithNotch.indexOf(device.model.split(',')[0]) >= 0) {
        // check for iPhone11
        mobileClasses.push('iPhoneX');
      }
    }
    this.globalHeaderService.mobileClasses = mobileClasses;
  }

  /**
   * Init router subscription to trigger changes on route change.
   * @returns {void}
   */
  private initRouterSubscription(): void {
    this.routerSubscription = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        // Update permissions on route change.
        this.updatePermissions();
      }
    });
  }

  /**
   * Update permissions.
   * @param {number} attempt - Number of attempts to update permissions.
   * @returns {void}
   */
  private updatePermissions(attempt: number = 0): void {
    const maxAttempts = 3;
    const attemptInterval = 1000;
    const envReviewersConfig = environment.static.reviewersConfig;
    const orgShortName = this.organisationService.organisation.shortName;
    let updateSuccess = false;

    const reviewerConfig: ReviewerConfig = envReviewersConfig.find(config => config.name === orgShortName);

    // Set try catch to prevent errors when jwt service cant get jwt data.
    try {
      this.permissionsService.reset(
        this.organisationService.organisation,
        {
          id: this.jwtService.getUserId(),
          roles: this.jwtService.getRoles() as RoleKeys[],
          locations: this.jwtService.getAccountLocations()
        },
        reviewerConfig
      );
      updateSuccess = true;
    }
    catch(e) {
      this.permissionsService.reset();
      updateSuccess = false;
    }

    // If permissions update failed and attempts are less than max attempts, try again.
    if (!updateSuccess && attempt < maxAttempts) {
      setTimeout(() => {
        this.updatePermissions(attempt + 1);
      }, attemptInterval);
    }
  }
  private async _setOrganisationBranding() {
    const orgDomain = typeof cordova !== 'undefined' ? brand.domain : location.hostname;
    let orgDomainEnvironment = orgDomain.split('.')[0];
    let org: OrganisationConfigModel = null;
    try {
      if (this.authService.isAuthenticated()) {
      // we need to get the org id from the logged in user
        org = await this.organisationService.getOrganisationConfig(this.jwtService.getContextOrg().id);
      } else if (this.organisationService.getCurrentOrganisationConfig() !== null) {
        // load org data from the local storage then get a refreshed version of it
        org = await this.organisationService.getOrganisationConfig(this.organisationService.getCurrentOrganisationConfig().orgId);
      } else if (!brand.showOrgSwitch || typeof cordova === 'undefined') {
        // not authenticated and on desktop or not etrainu app
        org = await this.organisationService.getOrganisationByDomain(orgDomain);
      }
    } catch(err) {
      console.error('catching error', err);
      // set a default value for orgDomainEnvironment
      if (['local.', 'dev', 'stg', 'assess'].indexOf(orgDomainEnvironment) >= 0) { // not using an org domain
        orgDomainEnvironment = 'etrainu';
      }
    };

    // determine if org switch button should show
    if (brand.domain && (typeof cordova !== 'undefined' || orgDomainEnvironment === 'localhost')) {
      this.organisationService.showOrgSwitchButton = brand.showOrgSwitch;
    }

    // let's check if we are using the mobile app, native apps have cordova defined and the brand config allows to toggle through orgs and there is no org already configured
    if (typeof cordova !== 'undefined' && brand.showOrgSwitch && !org) {
      await this.router.navigate(['organisation']);
      this.initRouterSubscription();
      this.showContent = true;
    } else {
      // set the org config on the org service
      this.organisationService.setOrg(org);
      // set the meta title
      this.setTitle();
      this.organisationService.setFavicon();
      // Init permissions service.
      this.updatePermissions();
      // set the title to the page
      this.globalHeaderService.titleChange.subscribe(title => {
        this.title = title;
      });

      // Init router subscription to trigger changes on route change.
      this.initRouterSubscription();
      // check if user is not authenticated and the route is not enrol, and the token is not being pass in the url redirect to login page
      // we need to include location.pathname and href as this.route has not been evaluated yet
      if (!this.authService.isAuthenticated() && !location.pathname.includes('/enrol') && !location.href.includes('?token=')) {
        this.authService.setPreviousUrl();
        await this.router.navigate(['login']);
      }
      // now we are ready to show the content
      this.showContent = true;

      // this could result in a error but a white page will be displayed, only happens if the domain doesn't exist in our DB
    }



  }
 }

import { AssessmentInstance } from "../pages/events/event/units/unit-grid/unit-grid.component";
import { RoleKeys } from "../services/permissions-service/permissions.service";

export enum AssessmentStatus {
  IN_PROGRESS = 'IN PROGRESS',
  TO_REVIEW = 'TO_REVIEW',
  COMPLETE = 'COMPLETE'
}

export enum AssessmentStatusHuman {
  IN_PROGRESS = 'Incomplete',
  TO_REVIEW = 'Incomplete',
  COMPLETE = 'Complete'
}
export enum UnitStatus {
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETE = 'COMPLETE',
  NO_ASSESSMENT = 'NO ASSESSMENT'
}
export enum SupplementaryInformationType {
  TIME_TAKEN = 'TimeTaken',
  NOTES = 'Notes',
  FILE = 'File'
}
export enum AssessmentActivitySummaryType {
  COURSE = 'BUNDLE',
  UNIT = 'INDUCTION',
  LEARNING_ASSESSMENT = 'ASSESSMENT'
}

export type EventFormAssessmentProperties = {
  allowAssessmentSessions: boolean;
  allowTrainingSessions: boolean;
  allowReviewerSessions: boolean;
  allowSessionsWithoutAssessorsOrTrainers: boolean;
  isSkillsMaintenanceCourse: boolean;
  eventEnrolmentLimit: boolean;
  hideInEventCreateForm: boolean;
  eventNameTemplate: string;
  eventApprovalRoles: RoleKeys[];
}

export interface CourseModelBase {
  id: string;
  name: string;
  assessmentEventProperties: EventFormAssessmentProperties;
  courseCode: string;
  courseNameAbbr: string;
  externalSystemProperties: { name: string, value: string }[];
  isAssessorAppCourse: boolean;
}

export interface CourseModel extends CourseModelBase {
  archiveDate: string | null;
  categories: Array<CourseCategoryModel> | null;
  cost: number;
  cpdPoints: string | null;
  description: string;
  internalName: string;
  supplierGroupId: number;
  units: Array<CourseUnitModel>;
  participants: Array<CourseParticipantModel>;
}

export class CourseCategoryModel {
  id: string;
  name: string;
  description: string;
}

export class CourseUnitModel {
  id: string;
  name: string;
  description: string;
  displayName: string;
  learningAssessments: Array<CourseLearningAssessmentModel>;
  courseUnit: {
    unitOrder: number
  };
  allowRPL?: boolean;
}

export class CourseLearningAssessmentModel {
  id: string;
  name: string;
  description: string;
  scenarios: Array<any>; // TODO: Find data model for this scenario
  type: string;
  supplementaryInfo: Array<CourseSupplementaryInfoModel>;
}

export class CourseSupplementaryInfoModel {
  id: string;
  assessmentFieldTypeId: SupplementaryInformationType;
  fieldName: string;
  fieldDescription: string;
  fieldRequired: boolean;
}

export class ParticipantCourseStatus {
  competentDate: Date;
  completeDate: Date;
  id: string;
  assessmentActivitySummary: AssessmentActivitySummary;
  assessedPreviously: boolean;
}
export class AssessmentActivitySummary {
  assessedEvent: string;
  assessedPreviously: boolean;
  assessedRPL?: boolean;
  assessedAuto?: boolean;
  assessedManual?: boolean;
  dateCreated: Date;
  dateUpdated: Date;
  id: string;
  type: AssessmentActivitySummaryType;
}
export class CourseParticipantModel {
  id: string;
  units: Array<CourseParticipantUnitModel>;
  course: ParticipantCourseStatus;
  assessmentSummary?: ParticipantUnitSummaryProgress;
}

export type ParticipantUnitSummaryProgress = {
  total: number,
  progress: number,
  theoryUnits: {
    total: number,
    progress: number,
  },
  practicalUnits: {
    total: number,
    progress: number,
  }
}

export class CourseParticipantUnitModel {
  id: string;
  unitId: string;
  status: UnitStatus;
  learningAssessments: Array<CourseParticipantLearningAssessment>;
  assessmentActivitySummary: AssessmentActivitySummary;
  assessedPreviously: boolean;
}

export class CourseParticipantLearningAssessment {
  learningAssessmentId: string;
  id: string;
  status: AssessmentStatus;
  dateStarted: Date = null;
  dateCompleted: Date = null;
  supplementaryInformation: Array<CourseParticipantLearningAssessmentSuppInfo>;
  assessmentActivitySummary: AssessmentActivitySummary;
  assessedPreviously: boolean;
}

export class CourseParticipantLearningAssessmentSuppInfo {
  id?: number;
  supplementaryInformationId?: string;
  value: string;
}

export interface AssessmentSubmit {
  unitInstances: AssessmentInstance[];
  learningAssessmentInstances: AssessmentInstance[];
  status?: AssessmentStatus;
  supplementaryInformation?: Array<AssessmentSubmitSuppInfo>;
}
export interface AssessmentSubmitSuppInfo {
  supplementaryInfoInstanceId?: number;
  supplementaryInfoId: string;
  supplementaryInfoValue: string;
}

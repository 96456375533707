import { Injectable } from '@angular/core';
import {
  EventAssessorModel,
  EventModel,
  EventReviewerModel,
  EventSessionModel,
  EventStatus,
  EventTrainerModel,
  ReviewerConfig
} from '../../models/event.model';
import { OrganisationConfigModel } from '../../models/organisation-config.model';
import { EventForm } from 'src/app/components/admin/event/event-form/event-form-types';
import { CourseModel } from 'src/app/models/course.model';

/**
 * IMPORTANT:
 * - When adding a new permission key, please add it to the defaultPermissionSet and defaultRolePermissionSets.
 * - When naming a key, make sure is is named positively and in the present tense.
 * - Please add/remove the key from the permissions spreadsheet here: https://docs.google.com/spreadsheets/d/1HIG9SiTWKEavYGDSwCmvhlgR7NWOJmkU0dbE5Dpyg1k/edit?usp=sharing
 */
export enum PermissionKeys {
  CAN_CREATE_EVENT = 'canCreateEvent',
  CAN_UPDATE_EVENT = 'canUpdateEvent',
  CAN_CLONE_EVENT = 'canCloneEvent',
  CAN_DELETE_EVENT = 'canDeleteEvent',
  CAN_CREATE_EVENT_TEMPLATE = 'canCreateEventTemplate',
  CAN_UPDATE_EVENT_TEMPLATE = 'canUpdateEventTemplate',
  CAN_DELETE_EVENT_TEMPLATE = 'canDeleteEventTemplate',
  CAN_MARK_EVENT_COMPLETE = 'canMarkEventComplete',
  CAN_MARK_EVENT_COMPLETE_WITH_REVIEW = 'canMarkEventCompleteWithReview',
  CAN_MARK_READY_FOR_REVIEW = 'canMarkReadyForReview',
  CAN_MANAGE_EXPECTED_DOCUMENTS = 'canManageExpectedDocuments',
  CAN_MANAGE_EVENT_SUMMARY_NOTES = 'canManageEventSummaryNotes',
  CAN_PROGRESS_REVIEW_STAGE = 'canProgressReviewStage',
  CAN_COMMENT_ON_REVIEW = 'canCommentOnReview',
  CAN_SEND_EMAILS_SMS = 'canSendEmailsSms',
  CAN_ENROL_PARTICIPANT = 'canEnrolParticipant',
  CAN_SIGN_IN_PARTICIPANT = 'canSignInParticipant',
  CAN_REMOVE_PARTICIPANT = 'canRemoveParticipant',
  CAN_EDIT_PARTICIPANT = 'canEditParticipant',
  CAN_DOWNLOAD_ATTENDANCE = 'canDownloadAttendance',
  CAN_DOWNLOAD_COMPLIANCE_REPORT = 'canDownloadComplianceReport',
  CAN_DOWNLOAD_PARTICIPANT_EXPORT = 'canDownloadParticipantExport',
  CAN_SELECT_ACCOUNT = 'canSelectAccount',
  CAN_INTERACT_WITH_EVENT = 'canInteractWithEvent',
  CAN_FILTER_MY_EVENTS =  'canFilterMyEvents',
  CAN_VIEW_EVENT =  'canViewEvent',
  CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER = 'canViewMyDepartmentEventFilter',
  CAN_VIEW_MY_SUBORG_EVENT_FILTER = 'canViewMySubOrgEventFilter',
  CAN_VIEW_MY_EVENTS_EVENT_FILTER = 'canViewMyEventsEventFilter',
  CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER = 'canViewIncompleteEventsEventFilter',
  CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS = 'canViewParticipantAdditionalRequirements',
  CAN_MARK_ALL_AS_COMPLETE_AND_RESET = 'canMarkAllAsCompleteAndReset',
  CAN_ASSESS_EVENT_WITH_COMPLIANCE = 'canAssessEventWithCompliance',
  CAN_CREATE_EVENT_WITHOUT_APPROVAL = 'canCreateEventWithoutApproval',
  CAN_UPDATE_APPROVED_EVENT = 'canEditApprovedEvent',
  CAN_DELETE_APPROVED_EVENT = 'canDeleteApprovedEvent',
  CAN_APPROVE_EVENT_REQUEST = 'canApproveEventRequest',
  CAN_DECLINE_EVENT_REQUEST = 'canDeclineEventRequest',
  CAN_UPDATE_EVENT_REQUEST = 'canUpdateEventRequest',
};

/**
 * IMPORTANT:
 * - When updating roles, please update the spreadsheet here: https://docs.google.com/spreadsheets/d/1HIG9SiTWKEavYGDSwCmvhlgR7NWOJmkU0dbE5Dpyg1k/edit?usp=sharing
 */
export enum RoleKeys {
  ORG_ADMIN = 'ORG_ADMIN',
  DEP_ADMIN = 'DEP_ADMIN',
  BRANCH_ADMIN = 'BRANCH_ADMIN',
  SUBORG_ADMIN = 'SUBORG_ADMIN',
  SUBORG_ADMIN_EXTERNAL = 'SUBORG_ADMIN_EXTERNAL',
  ASSESSOR = 'ASSESSOR',
  ASSESSOR_EXTERNAL = 'ASSESSOR_EXTERNAL',
  ENDORSED_DELEGATE = 'ENDORSED_DELEGATE',
  TRAINER = 'TRAINER',
  LEVEL_1_REVIEWER = 'LEVEL_1_REVIEWER',
  LEVEL_2_REVIEWER = 'LEVEL_2_REVIEWER',
  PARTICIPANT = 'PARTICIPANT'
};

export type authPermissionType = {
  isAllowed: boolean;
  msg: string;
}

type User = {
  id: string;
  roles: RoleKeys[];
  locations: string[];
};

type PermissionSet = {[key in PermissionKeys]: boolean};
type PartialPermissionSet = {[key in PermissionKeys]?: boolean};

type RolePermissionSets = {[key in RoleKeys]: PermissionSet};
type PartialRolePermissionSets = {[key in RoleKeys]?: PartialPermissionSet};

type Context = unknown;

/**
 * IMPORTANT:
 * - When making changes to permissions, you must update the permissions spreadsheet to reflect your changes.
 * - You can find the permissions spreadsheet here: https://docs.google.com/spreadsheets/d/1HIG9SiTWKEavYGDSwCmvhlgR7NWOJmkU0dbE5Dpyg1k/edit?usp=sharing
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private orgConfig: OrganisationConfigModel = null;
  private user: User = null;
  private reviewerConfig: ReviewerConfig = null;

  /**
   * Reset the permissions service with the current organisation config and user.
   * @param orgConfig - The current organisation config.
   * @param user - The current user.
   */
  public reset(orgConfig: OrganisationConfigModel = null, user: User = null, reviewerConfig: ReviewerConfig = null) {
    this.orgConfig = orgConfig;
    this.user = user;
    this.reviewerConfig = reviewerConfig;

    // If we don't have all the data yet, we don't continue initialising permissions.
    if (!this.user || !this.orgConfig) return;

    const { allowExternalOrgEventAdmin } = this.orgConfig.eventConfig.eventAdminForm;

    // If org config allows external org admins AND the current user is SUBORG_ADMIN.
    if (allowExternalOrgEventAdmin && this.isUserRole([RoleKeys.SUBORG_ADMIN])) {
      // Add SUBORG_ADMIN_EXTERNAL virtual role to the user.
      this.user.roles.push(RoleKeys.SUBORG_ADMIN_EXTERNAL);
    }

    // If org config allows external org admins AND the current user is ASSESSOR.
    if (allowExternalOrgEventAdmin && this.isUserRole([RoleKeys.ASSESSOR])) {
      // Add ASSESSOR_EXTERNAL virtual role to the user.
      this.user.roles.push(RoleKeys.ASSESSOR_EXTERNAL);
    }

    if (this.isUserRole([RoleKeys.BRANCH_ADMIN])) {
      // Check if user has DEP_ADMIN role and remove it from the user roles.
      this.user.roles = this.user.roles.filter(role => role !== RoleKeys.DEP_ADMIN);
    }
  }

  /**
   * Check if the user has the specified privilege.
   * @param pKey - The permission key to check in the permission set.
   * @param context - The context relating to the permission key.
   * @returns {boolean} - True if the user is granted the specified permission.
   */
  public hasPermission(pKey: PermissionKeys, context: Context = null): boolean {
    // If there is no user info or the organisation config is not set, return false for all permissions.
    if (!this.user || !this.orgConfig) return false;

    // Create new default permission sets instance to prevent the original from being modified.
    const defaultRolePermissionSets: RolePermissionSets = {...this.defaultRolePermissionSets};

    // Compress default permission sets into a single permission set where truthy values are prioritised.
    const defaultPermissionSet: PermissionSet = this.mergeRolePermissionSets(defaultRolePermissionSets, {...this.defaultPermissionSet}) as PermissionSet;

    if (context !== null) {
      // IMPORTANT: Contextual permissions will only override permissions in the case they return true.
      // This is to ensure that if a role the user has allows a permission but the context does not, the permission will NOT be denied.
      defaultPermissionSet[pKey] = this.manageContext(pKey, context) || defaultPermissionSet[pKey];
    }

    // Get single org permission set from orgs array of role based permission set.
    const orgPermissionSet: PartialPermissionSet = this.mergeRolePermissionSets({...(this.orgConfig?.permissions ?? {})}, {}) as PartialPermissionSet;

    // Merge the the org config permission set with the default permission set last so org config has final say on permissions.
    const userPermissionSet: PermissionSet = {...defaultPermissionSet, ...orgPermissionSet};

    // Check if the user has permission for the specified permission key.
    const hasPermission: boolean = userPermissionSet[pKey];

    return hasPermission;
  }

  /**
   * Merges the RolePermissionSets into a single permission set where truthy values are prioritised.
   * @param rolePermissionSets - The rolePermissionSets to consolidate.
   * @param baseSet - The base set to merge the rolePermissionSets into.
   * @returns {PermissionSet | PartialPermissionSet} - The merged permission set.
   */
  private mergeRolePermissionSets(rolePermissionSets: RolePermissionSets | PartialRolePermissionSets, baseSet: PermissionSet | PartialPermissionSet): PermissionSet | PartialPermissionSet {
    // Loop over each role in the userRoles array.
    for (const roleName of this.user.roles) {
      if (rolePermissionSets[roleName]){
        // Loop over each permission in the org role permission set.
        for (const pKey of Object.keys(rolePermissionSets[roleName])) {
          // If the corresponding permission in the baseSet is false, set it to the value of the permission in the RolePermissionSets.
          if (!baseSet[pKey]) {
            baseSet[pKey] = rolePermissionSets[roleName][pKey];
          }
        }
      }
    }

    return baseSet;
  }

  /**
   * Map the permission and context data to the correct function.
   * IMPORTANT: When updating contextual permissions, you must update the permissions spreadsheet to reflect your changes.
   * You can find the permissions spreadsheet here: https://docs.google.com/spreadsheets/d/1HIG9SiTWKEavYGDSwCmvhlgR7NWOJmkU0dbE5Dpyg1k/edit?usp=sharing
   * @param pKey - The permission key to check.
   * @param context - The context data relating to the permission.
   * @returns {boolean}
   */
  private manageContext(pKey: PermissionKeys, context: Context): boolean {
    let isValid: boolean = false;
    let event: EventModel | EventForm = null;
    let sessions: EventSessionModel[] = null;

    // Map the pKey to the correct contextual rules.
    switch (pKey) {
      case PermissionKeys.CAN_UPDATE_EVENT:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          )
        );
        break;

      case PermissionKeys.CAN_CLONE_EVENT:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          )
        );
        break;

      case PermissionKeys.CAN_DELETE_EVENT:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          )
        );
        break;

      case PermissionKeys.CAN_MARK_EVENT_COMPLETE:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && this.hasEventAssessorRelationship(event.eventAssessors))
        );
        break;

      case PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW:
        event = context as EventModel;
        isValid = this.canReviewerMarkEventComplete(event);
        break;

      case PermissionKeys.CAN_MARK_READY_FOR_REVIEW:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && this.hasEventAssessorRelationship(event.eventAssessors))
        );
        break;

      case PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && this.hasEventAssessorRelationship(event.eventAssessors)) ||
          (this.isUserRole([RoleKeys.TRAINER]) && this.hasEventTrainerRelationship(event.eventTrainers)) ||
          (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event))
        );
        break;

      case PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && this.hasEventAssessorRelationship(event.eventAssessors)) ||
          (this.isUserRole([RoleKeys.TRAINER]) && this.hasEventTrainerRelationship(event.eventTrainers)) ||
          (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event))
        );
        break;

      case PermissionKeys.CAN_PROGRESS_REVIEW_STAGE:
        event = context as EventModel;
        isValid = this.canReviewerProgressReviewStage(event);
        break;

      case PermissionKeys.CAN_COMMENT_ON_REVIEW:
        event = context as EventModel;
        isValid = this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event);
        break;

      case PermissionKeys.CAN_SEND_EMAILS_SMS:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          ) ||
          (this.isUserRole([RoleKeys.TRAINER]) && this.hasEventTrainerRelationship(event.eventTrainers)) ||
          (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event))
        );
        break;

      case PermissionKeys.CAN_ENROL_PARTICIPANT:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          ) ||
          (this.isUserRole([RoleKeys.TRAINER]) && this.hasEventTrainerRelationship(event.eventTrainers)) ||
          (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event))
        );
        break;

      case PermissionKeys.CAN_SIGN_IN_PARTICIPANT:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          ) ||
          (this.isUserRole([RoleKeys.TRAINER]) && this.hasEventTrainerRelationship(event.eventTrainers)) ||
          (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event))
        );
        break;

      case PermissionKeys.CAN_REMOVE_PARTICIPANT:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          ) ||
          (this.isUserRole([RoleKeys.TRAINER]) && this.hasEventTrainerRelationship(event.eventTrainers)) ||
          (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event))
        );
        break;

      case PermissionKeys.CAN_EDIT_PARTICIPANT:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          ) ||
          (this.isUserRole([RoleKeys.TRAINER]) && this.hasEventTrainerRelationship(event.eventTrainers)) ||
          (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event))
        );
        break;

      case PermissionKeys.CAN_SELECT_ACCOUNT:
        const accId = context as string;
        isValid = this.isUserRole([RoleKeys.SUBORG_ADMIN, RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && this.hasAccountLocationRelationship(accId);
        break;

      case PermissionKeys.CAN_INTERACT_WITH_EVENT:
        event = context as EventModel;
        isValid = (
          this.notUserRole([RoleKeys.PARTICIPANT]) &&
          (
            this.hasEventReviewerRelationship(event) ||
            this.hasEventAssessorRelationship(event.eventAssessors) ||
            this.hasEventTrainerRelationship(event.eventTrainers) ||
            this.hasAccountLocationRelationship(event.accounts[0]?.location.id)
          )
        );
        break;

      case PermissionKeys.CAN_VIEW_EVENT:
        event = context as EventModel;
        isValid = ((
          this.isUserRole([RoleKeys.ENDORSED_DELEGATE]) && (
            !event.isAccreditedCourse() ||
            this.hasEventAssessorRelationship(event.eventAssessors)
          )
        ));
        break;

      case PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS:
        event = context as EventModel;
        isValid = (
          (this.isUserRole([RoleKeys.SUBORG_ADMIN]) && this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) ||
          (this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) && (
            this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id))
          ) ||
          (this.isUserRole([RoleKeys.TRAINER]) && this.hasEventTrainerRelationship(event.eventTrainers)) ||
          (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER, RoleKeys.LEVEL_2_REVIEWER]) && this.hasEventReviewerRelationship(event))
        );
        break;

      case PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET:
        type contextType = { event: EventModel, courseId: string, participantId: string };
        event = (context as contextType).event;
        const courseId = (context as contextType).courseId;
        const participantId = (context as contextType).participantId;
        isValid = (
          ((this.user.id !== participantId) &&
          this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE]) &&
          (this.hasEventAssessorRelationship(event.eventAssessors) || this.hasAccountLocationRelationship(event.accounts[0]?.location.id)) &&
          (this.hasCourseAssessorRelationship(event.sessions, courseId)))
        );
        break;

      case PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL:
        event = context as EventModel | EventForm;
        isValid = this.checkUserHasEventApprovalRoles(event.courses);
        break;

      case PermissionKeys.CAN_UPDATE_APPROVED_EVENT:
        event = context as EventModel | EventForm;
        isValid = this.checkUserHasEventApprovalRoles(event.courses);
        break;

      case PermissionKeys.CAN_DELETE_APPROVED_EVENT:
        event = context as EventModel | EventForm;
        isValid = this.checkUserHasEventApprovalRoles(event.courses);
        break;

      case PermissionKeys.CAN_UPDATE_EVENT_REQUEST:
        event = context as EventModel | EventForm;
        isValid = this.checkUserHasEventApprovalRoles(event.courses) || this.checkUserCreatedEvent(event);
        break;

      case PermissionKeys.CAN_DECLINE_EVENT_REQUEST:
        event = context as EventModel | EventForm;
        isValid = this.checkUserHasEventApprovalRoles(event.courses) || this.checkUserCreatedEvent(event);
        break;

      case PermissionKeys.CAN_APPROVE_EVENT_REQUEST:
        event = context as EventModel | EventForm;
        isValid = this.checkUserHasEventApprovalRoles(event.courses);
        break;

      case PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE:
        sessions = context as EventSessionModel[];
        isValid = this.isUserRole([RoleKeys.ASSESSOR, RoleKeys.ENDORSED_DELEGATE, RoleKeys.TRAINER]) && this.hasCheckedComplianceAcknowledgement(sessions);
        break;

      default:
        isValid = false;
    }
    return isValid;
  }

  /**
   * Check if the users role is included in the training configs eventApprovalRoles.
   * @param courses {CourseModel[]} - The courses to check.
   * @returns {boolean} - True if the user has a role in the training configs eventApprovalRoles or no roles set.
   */
  private checkUserHasEventApprovalRoles(courses: CourseModel[]): boolean {
    // Check courses exist - courses are null or [] during template load/transition states
    if (!courses || !courses.length) return true;

    const allowedApprovalRoles = courses.reduce<RoleKeys[]>((acc, course) => {
      const approval = course.assessmentEventProperties.eventApprovalRoles;
      if (approval) {
        acc.push(...approval);
      }
      return acc;
    }, []);

    const hasAllowedRoles = allowedApprovalRoles.some( role => this.user.roles.includes(role)) || !allowedApprovalRoles.length;
    return hasAllowedRoles;
  }

  /**
   * Checks if the user is assigned to any of the specified roles.
   * @param roles - The roles to check.
   * @returns {boolean} - True if the user is assigned to any of the specified roles.
   */
  private isUserRole(roles: RoleKeys[]): boolean {
    return roles.some( role => this.user.roles.includes(role) );
  }

  /**
   * Checks if the user is NOT assigned to any of the specified roles.
   * @param roles - The roles to check.
   * @returns {boolean} - True if the user is NOT assigned to any of the specified roles.
   */
  private notUserRole(roles: RoleKeys[]): boolean {
    return roles.every( role => !this.user.roles.includes(role) );
  }

  /**
   * Check if the user has a relationship with the specified event/account.
   * @param locationId - The location ID to check.
   * @returns {boolean} - True if the user has a relationship with the specified event/account.
   */
  private hasAccountLocationRelationship(locationId: string): boolean {
    if (!locationId) return false;

    const userAccountLocations: string[] = this.user.locations;
    return userAccountLocations ? userAccountLocations.includes(locationId) : false;
  }

  /**
   * Checks if the current user is an assessor assigned to the specified event.
   * @param sessions - An array of sessions to get the assessors from.
   * @returns {boolean} - True if the current user is an assessor for the specified event.
   */
  private hasEventAssessorRelationship(assessors: EventAssessorModel[]): boolean {
    if (!assessors?.length) return false;

    // Check if the current user is an assessor in the array of assessors.
    const isRelated = this.checkUserAssignedRelationship(assessors);

    return isRelated;
  }

  /**
   * Checks if the current user is an assessor assigned to the specified course.
   * @param sessions - An array of event sessions to get the assessors from.
   * @param courseId - The course ID to check.
   * @returns {boolean} - True if the current user is an assessor for the specified course.
   */
  private hasCourseAssessorRelationship(sessions: EventSessionModel[], courseId: string): boolean {
    if (!sessions.length || !courseId) return false;

    return this.checkAssessorAssignedToCourse(sessions, courseId);
  }

  /**
   * Checks if the current user is an trainers assigned to the specified event.
   * @param sessions - An array of sessions to get the trainers from.
   * @returns {boolean} - True if the current user is a trainer for the specified event.
   */
   private hasEventTrainerRelationship(trainers: EventTrainerModel[]): boolean {
    if (!trainers.length) return false;

    // Check if the current user is a trainer in the array of trainers.
    const isRelated = this.checkUserAssignedRelationship(trainers);

    return isRelated;
  }

  /**
   * Checks if the user is a reviewer assigned to at least one review stage in the specified event.
   * @param event - The event to check.
   * @returns {boolean}
   */
  private hasEventReviewerRelationship(event: EventModel): boolean {
    if (!event || !event?.reviewStages) return false;

    // Check if the current user is a reviewer in the array of reviewers OR if the user is assigned as a reviewer at the all level.
    return this.checkUserAssignedRelationship(event.reviewStages.reviewers) || this.isAssignedAsAllLevelXReviewer(event);
  }

  /**
   * Checks if the current user is assigned to the specified event (as an assessor, trainer, or reviewer)
   * @param assignedUsers - An array or users assigned to the event.
   * @returns {boolean} - True if the current user is an assessor for the specified event.
   */
  private checkUserAssignedRelationship(assignedUsers: EventAssessorModel[] | EventTrainerModel[] | EventReviewerModel[]): boolean {
    const userId: string = this.user.id;
    return assignedUsers ? assignedUsers.some(assignedUser => assignedUser.id === userId) : false;
  }

  /**
   * Checks if the current user is the user who created the event
   * @param event - The event to check
   * @returns {boolean} True if the current user is the creator of the event
   */
  private checkUserCreatedEvent(event: EventModel | EventForm): boolean {
    const userId: string = this.user.id;

    if (!event.createdBy) {
      return false;
    }

    if (typeof event.createdBy === "string") {
      return event.createdBy === userId;
    }

    if (typeof event.createdBy === "object") {
      return event.createdBy.id === userId;
    }
    return false;
  }

  /**
   * Checks if the current user is assigned as a assessor to a specified course.
   * @param sessions - An array of event sessions to get the assessors from.
   * @param courseId - The course ID to check.
   * @returns {boolean} - True if the current user is assigned as an assessor to the specified course.
   */
  private checkAssessorAssignedToCourse(sessions: EventSessionModel[], courseId: string): boolean {
    const userId: string = this.user.id;
    for (const session of sessions) {
      const { assessors } = session;
      for (const assessor of assessors) {
        if (assessor.id === userId && assessor.courses.includes(courseId)) {
          return true; // Assessor is assigned to the course
        }
      }
    }
    return false; // Assessor is not assigned to the course
  }

  /**
   * Checks given reviewers to see if there are multiple review stages on the event.
   * @param reviewers - An array of reviewers to check.
   * @returns {boolean} True if there are multiple review stages on the event.
   */
  private hasMultipleReviewStage(reviewers: EventReviewerModel[]): boolean {
    // If the reviewers.reviewerOrder is greater than 1, then there are multiple review levels.
    return reviewers ? reviewers.some(reviewer => reviewer.reviewerOrder > 1) : false;
  }

  /**
   * Checks if the user is assigned to the event as an All Level 1 or All Level 2 reviewer.
   * @param event - The event to check.
   * @returns {boolean}
   */
  private isAssignedAsAllLevelXReviewer(event: EventModel): boolean {
    // Make sure the event has review stages.
    if (!event || !event?.reviewStages) {
      return false;
    }

    if (!this.reviewerConfig) {
      return false;
    }

    // Check if user is assigned to the event as an All Level 1 or All Level 2 reviewer.
    return event.reviewStages.reviewers.some(reviewer => {
      return (this.isUserRole([RoleKeys.LEVEL_1_REVIEWER]) && reviewer.id === this.reviewerConfig.allStageOneReviewers) ||
        (this.isUserRole([RoleKeys.LEVEL_2_REVIEWER]) && reviewer.id === this.reviewerConfig.allStageTwoReviewers);
    });
  }

  /**
   * Checks if the user is a reviewer for the current review stage of the event.
   * @param event - The event to check.
   * @returns {boolean}
   */
  private isActiveReviewer(event: EventModel): boolean {
    const level = event.reviewStages.currentReviewer;

    if (!event || !event?.reviewStages || !this.reviewerConfig) {
      return false;
    }

    const reviewerIdMap = {
      1: this.reviewerConfig.allStageOneReviewers,
      2: this.reviewerConfig.allStageTwoReviewers
    }

    const isActiveAll = event.reviewStages.reviewers.some(reviewer => {
      return (this.isUserRole([RoleKeys[`LEVEL_${level}_REVIEWER`]]) && reviewer.id === reviewerIdMap[level]);
    });

    const isActiveLevel = event.reviewStages.reviewers.some(reviewer => {
      return (this.isUserRole([RoleKeys[`LEVEL_${level}_REVIEWER`]]) && reviewer.id === this.user.id && reviewer.reviewerOrder === level);
    });

    return isActiveAll || isActiveLevel;
  }

  /**
   * Handles the context for the CAN_MARK_EVENT_COMPLETE permission.
   * @param event - The event to check.
   * @returns {boolean}
   */
  private canReviewerMarkEventComplete(event: EventModel): boolean {
    // Check if the user is assigned to the even as reviewer.
    const isAssignedReviewer = this.isActiveReviewer(event);

    // Check if the event is in review.
    const isInReview = event.status === EventStatus.IN_REVIEW;

    // Check if the user is a level 2 reviewer and the event is in stage 2 of review.
    const isLevel2AndReviewStage2 =
      this.isUserRole([RoleKeys.LEVEL_2_REVIEWER]) &&
      event?.reviewStages.currentReviewer === 2;

    // Check if the user is a level 1 reviewer and the event is in stage 1 of review and there is only one review stage.
    const isLevel1AndReviewStage1 =
      this.isUserRole([RoleKeys.LEVEL_1_REVIEWER]) &&
      event?.reviewStages.currentReviewer === 1 &&
      !this.hasMultipleReviewStage(event?.reviewStages.reviewers);

    return isInReview && isAssignedReviewer && (isLevel2AndReviewStage2 || isLevel1AndReviewStage1);
  }

  /**
   * Handles the context for the CAN_PROGRESS_REVIEW_STAGE permission.
   * @param event - The event to check.
   * @returns {boolean}
   */
  private canReviewerProgressReviewStage(event: EventModel): boolean {
    // Check if the user is assigned to the even as reviewer.
    const isAssignedReviewer = this.isActiveReviewer(event);

    // Check if the user is a level 2 reviewer and the event is in stage 2 of review.
    const isLevel2AndReviewStage2 =
      this.isUserRole([RoleKeys.LEVEL_2_REVIEWER]) &&
      event?.reviewStages.currentReviewer === 2;

    // Check if the user is a level 1 reviewer and the event is in stage 1 of review.
    const isLevel1AndReviewStage1 =
      this.isUserRole([RoleKeys.LEVEL_1_REVIEWER]) &&
      event?.reviewStages.currentReviewer === 1;

    return isAssignedReviewer && (isLevel2AndReviewStage2 || isLevel1AndReviewStage1);
  }

  /**
   * Handles the context for the CAN_ASSESS_EVENT_WITH_COMPLIANCE permission.
   * @param sessions - The sessions to check.
   * @returns {boolean}
   */
  private hasCheckedComplianceAcknowledgement(sessions: EventSessionModel[]): boolean {
    // Helper function to check if the role's id matches the user's id and if they have acknowledged compliance
    const checkCompliance = (role: EventAssessorModel | EventTrainerModel): boolean => {
      return this.user.id === role.id && role.hasAcknowledgedCompliance;
    };

    for (const session of sessions) {
      const { assessors, trainers } = session;
      const AssessorTrainers = [...assessors, ...trainers];

      // Check if any Assessor/Trainer matches the user's id and has acknowledged compliance
      if (AssessorTrainers.some(AssessorTrainer => checkCompliance(AssessorTrainer))) {
        return true;
      }
    }

    return false;
  }


  /******** DEFAULT PERMISSIONS/VALUES START HERE *******/

  /**
   * The default permission set.
   */
  private defaultPermissionSet: PermissionSet = {
    [PermissionKeys.CAN_CREATE_EVENT]: false,
    [PermissionKeys.CAN_UPDATE_EVENT]: false,
    [PermissionKeys.CAN_CLONE_EVENT]: false,
    [PermissionKeys.CAN_DELETE_EVENT]: false,
    [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
    [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
    [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
    [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: false,
    [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
    [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: false,
    [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: false,
    [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: false,
    [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
    [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
    [PermissionKeys.CAN_SEND_EMAILS_SMS]: false,
    [PermissionKeys.CAN_ENROL_PARTICIPANT]: false,
    [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: false,
    [PermissionKeys.CAN_REMOVE_PARTICIPANT]: false,
    [PermissionKeys.CAN_EDIT_PARTICIPANT]: false,
    [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: false,
    [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: false,
    [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: false,
    [PermissionKeys.CAN_SELECT_ACCOUNT]: false,
    [PermissionKeys.CAN_INTERACT_WITH_EVENT]: false,
    [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
    [PermissionKeys.CAN_VIEW_EVENT]: false,
    [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
    [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
    [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
    [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: false,
    [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: false,
    [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
    [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
    [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
    [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
    [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
    [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
    [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
    [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
  };

  /**
   * The default/base role permission sets.
   * IMPORTANT: Any changes made to these values need to be updated in the permissions spreadsheet.
   * You can find the permissions spreadsheet here: https://docs.google.com/spreadsheets/d/1HIG9SiTWKEavYGDSwCmvhlgR7NWOJmkU0dbE5Dpyg1k/edit?usp=sharing
   */
  readonly defaultRolePermissionSets: RolePermissionSets = {
    [RoleKeys.ORG_ADMIN]: {
      [PermissionKeys.CAN_CREATE_EVENT]: true,
      [PermissionKeys.CAN_UPDATE_EVENT]: true,
      [PermissionKeys.CAN_CLONE_EVENT]: true,
      [PermissionKeys.CAN_DELETE_EVENT]: true,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: true,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: true,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: true,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: true,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: true,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: true,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: true,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: true,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: true,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: true,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: true,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: true,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: true,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: true,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: true,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: true,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.DEP_ADMIN]: {
      [PermissionKeys.CAN_CREATE_EVENT]: true,
      [PermissionKeys.CAN_UPDATE_EVENT]: true,
      [PermissionKeys.CAN_CLONE_EVENT]: true,
      [PermissionKeys.CAN_DELETE_EVENT]: true,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: true,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: true,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: true,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: true,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: true,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: true,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: true,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: true,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: true,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: true,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: true,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: true,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: true,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: true,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: true,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: true,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.BRANCH_ADMIN]: {
      [PermissionKeys.CAN_CREATE_EVENT]: true,
      [PermissionKeys.CAN_UPDATE_EVENT]: true,
      [PermissionKeys.CAN_CLONE_EVENT]: true,
      [PermissionKeys.CAN_DELETE_EVENT]: true,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: true,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: true,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: true,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: true,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: true,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: true,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: true,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: true,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: true,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: true,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: true,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: true,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: true,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.SUBORG_ADMIN]: {
      [PermissionKeys.CAN_CREATE_EVENT]: true,
      [PermissionKeys.CAN_UPDATE_EVENT]: false,
      [PermissionKeys.CAN_CLONE_EVENT]: false,
      [PermissionKeys.CAN_DELETE_EVENT]: false,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: false,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: false,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: false,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: false,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: false,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: false,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: false,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: false,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: false,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: false,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: false,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.SUBORG_ADMIN_EXTERNAL]: {
      [PermissionKeys.CAN_CREATE_EVENT]: true,
      [PermissionKeys.CAN_UPDATE_EVENT]: true,
      [PermissionKeys.CAN_CLONE_EVENT]: true,
      [PermissionKeys.CAN_DELETE_EVENT]: true,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: true,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: true,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: true,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: true,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: true,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: true,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: true,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: true,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: true,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: true,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: true,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: true,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.ASSESSOR]: {
      [PermissionKeys.CAN_CREATE_EVENT]: true,
      [PermissionKeys.CAN_UPDATE_EVENT]: false,
      [PermissionKeys.CAN_CLONE_EVENT]: false,
      [PermissionKeys.CAN_DELETE_EVENT]: false,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: false,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: false,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: false,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: false,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: false,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: false,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: false,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: false,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: false,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: false,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: true,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: false,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.ASSESSOR_EXTERNAL]: {
      [PermissionKeys.CAN_CREATE_EVENT]: true,
      [PermissionKeys.CAN_UPDATE_EVENT]: true,
      [PermissionKeys.CAN_CLONE_EVENT]: true,
      [PermissionKeys.CAN_DELETE_EVENT]: true,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: true,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: true,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: true,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: true,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: true,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: true,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: true,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: true,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: true,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: true,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: true,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: true,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: true,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.ENDORSED_DELEGATE]: {
      [PermissionKeys.CAN_CREATE_EVENT]: true,
      [PermissionKeys.CAN_UPDATE_EVENT]: false,
      [PermissionKeys.CAN_CLONE_EVENT]: false,
      [PermissionKeys.CAN_DELETE_EVENT]: false,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: false,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: false,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: false,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: false,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: false,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: false,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: false,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: false,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: false,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: false,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: true,
      [PermissionKeys.CAN_VIEW_EVENT]: false,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: false,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.TRAINER]: {
      [PermissionKeys.CAN_CREATE_EVENT]: false,
      [PermissionKeys.CAN_UPDATE_EVENT]: false,
      [PermissionKeys.CAN_CLONE_EVENT]: false,
      [PermissionKeys.CAN_DELETE_EVENT]: false,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: false,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: false,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: false,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: false,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: false,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: false,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: false,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: false,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: false,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: false,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: false,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.LEVEL_1_REVIEWER]: {
      [PermissionKeys.CAN_CREATE_EVENT]: false,
      [PermissionKeys.CAN_UPDATE_EVENT]: false,
      [PermissionKeys.CAN_CLONE_EVENT]: false,
      [PermissionKeys.CAN_DELETE_EVENT]: false,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: false,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: false,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: false,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: false,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: false,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: false,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: false,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: false,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: false,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: false,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: false,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.LEVEL_2_REVIEWER]: {
      [PermissionKeys.CAN_CREATE_EVENT]: false,
      [PermissionKeys.CAN_UPDATE_EVENT]: false,
      [PermissionKeys.CAN_CLONE_EVENT]: false,
      [PermissionKeys.CAN_DELETE_EVENT]: false,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: false,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: false,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: false,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: false,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: false,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: false,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: false,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: false,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: true,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: true,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: true,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: false,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: false,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: true,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: true,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: false,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    },
    [RoleKeys.PARTICIPANT]: {
      [PermissionKeys.CAN_CREATE_EVENT]: false,
      [PermissionKeys.CAN_UPDATE_EVENT]: false,
      [PermissionKeys.CAN_CLONE_EVENT]: false,
      [PermissionKeys.CAN_DELETE_EVENT]: false,
      [PermissionKeys.CAN_CREATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_DELETE_EVENT_TEMPLATE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE]: false,
      [PermissionKeys.CAN_MARK_EVENT_COMPLETE_WITH_REVIEW]: false,
      [PermissionKeys.CAN_MARK_READY_FOR_REVIEW]: false,
      [PermissionKeys.CAN_MANAGE_EXPECTED_DOCUMENTS]: false,
      [PermissionKeys.CAN_MANAGE_EVENT_SUMMARY_NOTES]: false,
      [PermissionKeys.CAN_PROGRESS_REVIEW_STAGE]: false,
      [PermissionKeys.CAN_COMMENT_ON_REVIEW]: false,
      [PermissionKeys.CAN_SEND_EMAILS_SMS]: false,
      [PermissionKeys.CAN_ENROL_PARTICIPANT]: false,
      [PermissionKeys.CAN_SIGN_IN_PARTICIPANT]: false,
      [PermissionKeys.CAN_REMOVE_PARTICIPANT]: false,
      [PermissionKeys.CAN_EDIT_PARTICIPANT]: false,
      [PermissionKeys.CAN_DOWNLOAD_ATTENDANCE]: false,
      [PermissionKeys.CAN_DOWNLOAD_COMPLIANCE_REPORT]: false,
      [PermissionKeys.CAN_DOWNLOAD_PARTICIPANT_EXPORT]: false,
      [PermissionKeys.CAN_SELECT_ACCOUNT]: false,
      [PermissionKeys.CAN_INTERACT_WITH_EVENT]: false,
      [PermissionKeys.CAN_FILTER_MY_EVENTS]: false,
      [PermissionKeys.CAN_VIEW_EVENT]: false,
      [PermissionKeys.CAN_VIEW_MY_DEPARTMENT_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_SUBORG_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_MY_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_INCOMPLETE_EVENTS_EVENT_FILTER]: false,
      [PermissionKeys.CAN_VIEW_PARTICIPANT_ADDITIONAL_REQUIREMENTS]: false,
      [PermissionKeys.CAN_MARK_ALL_AS_COMPLETE_AND_RESET]: false,
      [PermissionKeys.CAN_CREATE_EVENT_WITHOUT_APPROVAL]: false,
      [PermissionKeys.CAN_UPDATE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_DELETE_APPROVED_EVENT]: false,
      [PermissionKeys.CAN_APPROVE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_DECLINE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_UPDATE_EVENT_REQUEST]: false,
      [PermissionKeys.CAN_ASSESS_EVENT_WITH_COMPLIANCE]: false,
    }
  };
}

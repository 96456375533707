import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardService } from './services/auth-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'organisation',
    pathMatch: 'full',
  },
  {
    path: 'organisation',
    loadChildren: () => import('./pages/organisation/organisation.module').then(m => m.OrganisationModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'templates',
    loadChildren: () => import('./pages/events/templates/templates.module').then(m => m.TemplatesModule),
  },
  {
    path: 'requests',
    loadChildren: () => import('./pages/events/event/requests/requests.module').then(m => m.RequestsModule),
  },
  {
    path: 'events/request/:id/update',
    loadChildren: () => import('./pages/events/event/requests/request-review-component/request-review.module').then(m => m.RequestReviewModule),
    canActivate: [AuthGuardService],
    data: { title: 'Update Event Request' },
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'enrol',
    loadChildren: () => import('./pages/enrol/event/event-enrol.module').then(m => m.EventEnrolmentModule)
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  providers: [AuthGuardService],
  exports: [RouterModule]
})

export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { Observable ,  throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { JwtService, UserRoles } from './jwt.service';
import { OrganisationService } from './organisation.service';
import { EventModel } from '../models/event.model';
import { EventsService } from './events.service';
import { CourseModel } from '../models/course.model';
import {UndoAssessmentService} from "../pages/events/event/units/undo-assessment.service";
import { Router } from '@angular/router';

export enum Roles{
  ORG_ADMIN = "ORG_ADMIN",
  DEP_ADMIN = "DEP_ADMIN",
  SUBORG_ADMIN = "SUBORG_ADMIN",
  ASSESSOR = "ASSESSOR",
  PARTNERSHIP = "PARTNERSHIP",
  REVIEWER = "REVIEWER",
  LEVEL_1_REVIEWER = 'LEVEL_1_REVIEWER',
  LEVEL_2_REVIEWER = 'LEVEL_2_REVIEWER'
}
@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private organisationService: OrganisationService,
    private eventsService: EventsService,
    private undoAssessmentService: UndoAssessmentService,
    private router: Router
  ) {
    window.addEventListener('storage', this.handleUserStorageChange.bind(this));
  }

  public login(username: string, password: string, appRequestPath: string|null = null): Observable<any> {
    const orgId: string = this.organisationService.organisation.orgId;

    return this.http.post(environment.api.login, {
      username,
      password,
      orgId
    }, {
      headers: {
        ...((appRequestPath && appRequestPath.length) && {
          'x-etu-app-request-path': appRequestPath
        }),
      }
    }).pipe(
      map((response: any) => {
        if (response.IdToken) {
          localStorage.setItem('current_user', response.IdToken);
          localStorage.removeItem('filterSettings');
          this.undoAssessmentService.resetUndoActions();
          return response;
        } else {
          return throwError('Unknown User');
        }
      }), catchError ((err) => {
        return throwError(err);
      }
    ));
  }

  public loginWithJwt (jwt: string) {
    localStorage.setItem('current_user', jwt);
    localStorage.removeItem('filterSettings');
    this.undoAssessmentService.resetUndoActions();
    if (!this.jwtService.getUserId()) {
      localStorage.setItem('current_user', '');
    }
  }
  public logout() {
    localStorage.removeItem('current_user');
  }

  public isAuthenticated(): boolean {
    // do authentication check here, this will be used by router guard to determine if user is logged in or not
    if (localStorage.getItem('current_user')
      && (localStorage.getItem('current_user') !== '' && localStorage.getItem('current_user') !== 'undefined')) {
      return true;
    } else {
      return false;
    }
  }

  public notParticipant(): boolean {
      if (
        (this.jwtService.getRoles().includes(UserRoles.ASSESSOR) ||
         this.jwtService.getRoles().includes(UserRoles.ACCOUNT_ADMIN) ||
         this.jwtService.getRoles().includes(UserRoles.TRAINER) ||
         this.jwtService.getRoles().includes(UserRoles.LEVEL_1_REVIEWER) ||
         this.jwtService.getRoles().includes(UserRoles.LEVEL_2_REVIEWER) ||
         this.jwtService.getRoles().includes(UserRoles.ENDORSED_DELEGATE))
      ) {
        return true;
      } else {
        return false;
      }
  }


  // This function checks if you are an assessor in the given course
  public checkCourseAssessorStatus(course: CourseModel, event: EventModel) {
    return !!(this.eventsService.getEventAssessors(event).filter(assessor => {
      if (this.jwtService.getUserId() === assessor.id && assessor.courses.includes(course.id)) {
        return assessor;
      }
    })).length;
  }

  public getToken(): string {
    return localStorage.getItem('current_user');
  }


  /**
   * Navigate user to login page if user is not authenticated on user storage change.
   * @param event StorageEvent
   * @returns void
   */
  private handleUserStorageChange(event: StorageEvent): void {
    if (event.key !== 'current_user') {
      return;
    }

    if (!this.isAuthenticated()) {
      this.router.navigate(['/login']);
    }
  }

  /**
   * Set previous url in local storage.
   * @returns void
   */
  public setPreviousUrl(): void {
    const url = location.pathname + location.search;
    const previousUrlBlacklist = ['/login'];

    if (previousUrlBlacklist.includes(url)) {
      return;
    }

    localStorage.setItem('previous_url', url);
  }

  /**
   * Get previous url from local storage and clear it.
   * @returns string
   */
  public getPreviousUrl(): string {
    const previousUrl = localStorage.getItem('previous_url');
    localStorage.removeItem('previous_url');

    return previousUrl;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from "../../directives/directives.module";

@NgModule({
  imports: [
    CommonModule, TranslateModule,
    DirectivesModule
  ],
  exports: [
    LoadingComponent
  ],
  declarations: [LoadingComponent]
})
export class LoadingModule { }

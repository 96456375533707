import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisPipe } from '../pipes/ellipsis.pipe';
import { TimezoneCleanPipe } from '../pipes/timezone-clean.pipe';
import {TimezoneCleanBulkPipe} from '../pipes/timezone-clean-bulk.pipe';
import { ArrayToStringPipe } from '../pipes/array-to-string.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  exports : [
    EllipsisPipe,
    TimezoneCleanPipe,
    TimezoneCleanBulkPipe,
    ArrayToStringPipe
  ],
  declarations: [
    EllipsisPipe,
    TimezoneCleanPipe,
    TimezoneCleanBulkPipe,
    ArrayToStringPipe
  ]
})
export class PipesModule { }

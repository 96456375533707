import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {AuthService, Roles} from './auth.service';
import { JwtService } from './jwt.service';
import { OrganisationService } from './organisation.service';

declare var cordova;

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    private jwtService: JwtService,
    public router: Router,
    private route: ActivatedRoute,
    private organisationService: OrganisationService,
    private location: Location
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // if token -> then login with jwt and navigate to next route
    if ('token' in route.queryParams) {
      this.auth.loginWithJwt(route.queryParams['token']);
      const redirectTo = state.url.split('?')[0];
      let queryParams = {...route.queryParams};
      delete queryParams.token; 
      if (state.url !== '/') {
        this.router.navigate(redirectTo.substring(1, redirectTo.length).split('/'), {relativeTo: null, queryParams});
        return true;
      }
      this.router.navigate(['.'], {relativeTo: this.route});
      return true;
    }
    // if not authenticated
    if (!this.auth.isAuthenticated()) {
      if (this.organisationService.hasOrgSet()) {
        // if user has selected an org but not logged in
        // add a check to see if the user is trying to enrol
        if (state.url.indexOf('enrol') > 0) {
          return true;
        }
        this.router.navigate(['login']);
        return true;
      } else if (state.url.indexOf('organisation') < 0 ) {
        if (typeof cordova === 'undefined') {
          // reload the page as we should not be going to the org selector on desktop
          location.reload();
          return false;
        } else {
          // if the user has navigated to a page that is not the org selector page but no org is set
          this.router.navigate(['organisation']);
        }
        return true;
      } else if (state.url.indexOf('organisation') > -1) {
        if (typeof cordova === 'undefined') {
          // reload the page as we should not be going to the org selector on desktop
          location.reload();
          return false;
        }
        // allow user access to the org selector page
        return true;
      } else {
        return false;
      }
    } else if (state.url.indexOf('organisation') >= 0 && this.organisationService.hasOrgSet() && this.auth.isAuthenticated()) {
      // allow access to dashboard if org is set and has token
      this.router.navigate(['dashboard']);
      return true;
    } else {
      if ('token' in route.queryParams) {
        const redirectTo = state.url.split('?')[0];
        if (state.url !== '/') {
          this.router.navigate(redirectTo.substring(1, redirectTo.length).split('/'), {relativeTo: null});
        } else {
          this.router.navigate(['.'], {relativeTo: this.route});
        }
      } else {
        const roles = this.jwtService.getRoles();
        if (roles.includes(Roles.PARTNERSHIP) && roles.length === 1) {
          if (state.url.indexOf('enrol') > 0) {
            return true;
          } else {
            // redirect to enrol page with event ID or back to login page if no event id available
            this.router.navigate(['enrol'])
            return false;
          }
        }
        return true;
      }
    }
  }

  resolve(): void {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['dashboard']);
    }
  }
}

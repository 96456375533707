import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of, Subject } from 'rxjs';
import * as _ from 'lodash';
import {
  catchError,
  debounceTime,
  distinctUntilChanged, map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ApiUtilsService } from '../shared/api-utils.service';
import { JwtService } from './jwt.service';
import { IFindUserByExternalSystemId } from '../models/accounts.service.model';

const className = 'AccountService';

export interface AccountSearchItem {
  accountId: number;
  externalId: string;
  hieratchyLevel: number;
  name: string;
  parent_accountId: number;
  parent_externalId: string;
  parent_name: string;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
  public isLoading = false;
  constructor(private http: HttpClient, private jwtService: JwtService, private apiUtilsService: ApiUtilsService) {}

  private search(term: string, hierarchyLevel: string, orgExternalId?: string): Observable<any> {
    if (term && term.length >= 3) {
      this.isLoading = true;

      if (!orgExternalId) {
        orgExternalId = this.jwtService.getContextOrg().id;
      }

      const url = this.apiUtilsService.setApiUrl(environment.api.partnershipSearch, [
        { key: '{id}', value: orgExternalId },
        { key: '{hierarchyLevel}', value: hierarchyLevel }
      ]);

      return this.http.get(url, { params: { query: term } }).pipe(
        map((response: Array<AccountSearchItem>) => {
          return response.sort((a1: AccountSearchItem, a2: AccountSearchItem) => (a1.name.localeCompare(a2.name)));
        }),
        catchError((err) => {
          if (err.status >= 500) {
            return of (Error('Something went wrong searching accounts please try again'));
          } else if (err.status === 404) {
            return of([]);
          } else {
            return of(err);
          }
        })
      );
    } else {
      return of([]);
    }
  }

  public typeahead(searchSubject: Subject<any>) {
    return searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((hierarchy) => this.search(hierarchy, '3')) // default 2 or 3 for partnerships(ie. participant groups)
    );
  }

  public getHierarchy(orgExternalId: string) {
    const url = this.apiUtilsService.setApiUrl(environment.api.getAccountHierarchy, [{
      key: '{id}', value: orgExternalId
    }]);

    return this.http.get(url);
  }

  public findUsersByExternalSystemId(
    findUserByExternalSystemIdInput: { externalSystemId: string }[]
  ): Observable<IFindUserByExternalSystemId[]> {
    const signature = className + '.findUsersByExternalSystemId: ';

    const requiredKeys = [
      'user.id',
      'user.externalId',
      'user.dateCreated',
      'user.dateUpdated',
      'user.createdByUserId',
      'user.username',
      'user.email',
      'user.firstName',
      'user.lastName',
      'explicitPartnershipAccounts'
    ];

    const url = this.apiUtilsService.setApiUrl(environment.api.findUsersByExternalSystemId, []);

    return this.http.post<IFindUserByExternalSystemId[]>(url, findUserByExternalSystemIdInput).pipe(
      tap(results => console.debug(signature + `Returned[${results.length}] Results`)),
      map(results => results.filter( (result, idx) => {
        const isValid = _.every(requiredKeys, key => _.has(result, key));

        if(!isValid) {
          // @todo This needs to be caught by raven/sentry
          console.error(signature + `Item at Idx[${idx}] is not valid.`);
        }

        return  isValid;
      }))
    );
  }
}

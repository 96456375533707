import { CourseParticipantUnitModel, ParticipantCourseStatus } from './course.model';
import { Action } from "../components/table-view/table-view.component"

export class ParticipantModel {
  id: string;
  status: ParticipantStatus;
  fullName: string;
  externalId: string;
  sessionRegistrations: SessionRegistration[] = [];
  courses: {
    id: string,
    name: string
  }[];
  externalSystemId?: string;
  enrolment?: ParticipantEnrolment;
  explicitPartnershipAccounts?: explicitPartnershipAccount[];
  dateCreated: Date;
  dateUpdated: Date;

  public reset() {
    this.status = ParticipantStatus.REGISTERED;
    this.fullName = '';
    this.externalId = '';
    this.sessionRegistrations = [];
  }
}

export type ParticipantEnrolment = {
  dateCreated: string;
  dateUpdated: string;
  processedBy: string;
  enrolmentQuestions: EnrolmentQuestion[];
  specialNeeds?: string;
}

export type EnrolmentQuestion = {
  responses: EnrolmentQuestionResponse[];
  questionText: string;
  questionType: "single" | "text" | "toggle" | "multiple" | "checkbox";
  flag: {
    id: string,
    type: string,
    text: string
  }
}

export type EnrolmentQuestionResponse = {
  responseText: string | string[] | null;
  responseType: "string" | "boolean";
  responseValue: string | string[] | boolean | null;
}

export class SessionRegistration {
  sessionId: string;
  acceptTandC: boolean;
  bibNumber: number;
  imageUrl: string;
  specialNeeds: string;
  registrationTimestamp: Date;
}

export class ParticipantRegistration {

  constructor (
    public event: {name?: string, id: string} = {name: '', id: ''},
    public participant: {
      id: string,
      firstName: string,
      lastName: string,
      status: ParticipantStatus
    } = {
      id: '',
      firstName: '',
      lastName: '',
      status: null
    },
    public courses: {id: string, name: string}[] = [],
    public sessionRegistrations: SessionRegistration = {
      sessionId: '',
      bibNumber: 0,
      acceptTandC: false,
      specialNeeds: '',
      imageUrl: '',
      registrationTimestamp: new Date()
    }, public imageUrl: string = '') {
  }

    public get () {
      return {
        event: this.event,
        participant: this.participant,
        courses: this.courses,
        sessionRegistrations: [this.sessionRegistrations],
        imageUrl: this.imageUrl
      };
    }

    public reset () {
      this.event.name = '';
      this.event.id = '';
      this.participant = {
        id: '',
        firstName: '',
        lastName: '',
        status: null
      };
      this.courses = [];
      this.sessionRegistrations = {
        sessionId: '',
        bibNumber: 0,
        acceptTandC: false,
        specialNeeds: '',
        imageUrl: '',
        registrationTimestamp: new Date()
      };
      this.imageUrl = '';
    }
}

export class ParticipantRegistrationResponse {
  _id: string;
  status: ParticipantStatus;
  participant: {
    id: string,
    name: string,
  };
  event: {
    name: string,
    id: string
  };
  courses: Array<{
    id: string,
    name: string
  }>;
  sessionRegistrations: Array<SessionRegistration>;
  unit_statuses: Array<{
    courseId: string,
    participants: Array<{
      id: string,
      units: Array<CourseParticipantUnitModel>,
      course: ParticipantCourseStatus
    }>
  }>;
}

export const enrolmentQuestionsStatus = {
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete'
} as const;

export type EnrolmentQuestionsStatus = typeof enrolmentQuestionsStatus[keyof typeof enrolmentQuestionsStatus];

export type ParticipantTableRow = {
  fullName: string;
  id: string;
  isProcessing: boolean;
  status: ParticipantStatus;
  action?: Action[] | string;
  specialNeeds?: string;
  contact?: string[];
  bibNumber?: string | number;
  dateCreated?: Date;
  dateUpdated?: Date;
  additionalRequirements?: string;
  enrolmentQuestions?: EnrolmentQuestionsStatus;
}

export type explicitPartnershipAccount = {
  accountId: number;
  externalId: number;
  hierarchyLevel: number;
  name: string;
  parent_accountId: number;
  parent_externalId: number;
  parent_name: string;
  ancestors?: explicitPartnershipAccount[];
  userRoles?: string[];
}

export enum ParticipantStatus {
  INTERESTED = 'Interested',
  WAITLISTED = 'Waitlisted',
  REGISTERED = 'Registered',
  ENROLLED = 'Enrolled',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  SESSION_COMPLETED = 'Session Completed',
  INITIATED_ENROLMENT = 'Initiated Enrolment'
}

export enum ParticipantStatusButton {
  SIGN_IN = 'Sign In',
  ENROL = 'Enrol'
}
